// Light Gallery
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

// Setting up the Gallery
const $lgContainer = document.getElementById("gallery-container");
const lg = lightGallery($lgContainer, {
    speed: 500,
    container: $lgContainer,
    // Do not allow users to close the gallery
    closable: false,
    // Add maximize icon to enlarge the gallery
    showMaximizeIcon: true,
    plugins: [lgZoom, lgThumbnail]
});
lg.openGallery(0);